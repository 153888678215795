import { ReactElement, ReactNode, Ref, forwardRef } from 'react';
import Image from 'next/image';
import clsx from 'clsx';
import { Button, ButtonContentProps } from '@/components/Button/Button';
import { Link } from '@/components/CustomLink';
type ConditionalWrapperProps = {
  condition: boolean;
  wrapper: (children: ReactNode) => ReactElement | null;
  children: ReactNode;
};
const ConditionalWrapper = ({
  condition,
  wrapper,
  children
}: ConditionalWrapperProps) => condition ? wrapper(children) : children;
type Props = {
  className?: string;
  imageSrc?: {
    url: string;
    dimensions: {
      width: number;
      height: number;
    };
  };
  imageAlt?: string;
  heightStretch?: boolean;
  imageWidth?: number;
  title?: string;
  text?: string;
  link?: {
    href?: string;
    target?: string;
  };
  borderRadius?: boolean;
  gradientColorStop1?: string;
  gradientColorStop2?: string;
  gradientDirection?: string;
  buttonText?: string;
  buttonVariant?: ButtonContentProps['variant'];
  buttonSize?: ButtonContentProps['size'];
  buttonShowIcon?: ButtonContentProps['showIcon'];
  variant?: 'dark' | 'light';
  hoverEffect?: boolean;
};
export const GradientCard = forwardRef(function GradientCard({
  className,
  imageSrc,
  imageAlt,
  imageWidth = 48,
  title,
  text,
  link,
  buttonText,
  borderRadius,
  gradientColorStop1,
  gradientColorStop2,
  gradientDirection,
  variant = 'dark',
  hoverEffect,
  heightStretch
}: Props, ref: Ref<HTMLDivElement>) {
  const buttonSettings = {
    dark: '[&>svg]:!fill-white !text-white',
    light: '[&>svg]:!fill-bc-black !text-bc-black'
  };
  const textColor = {
    dark: 'text-white',
    light: 'text-bc-white'
  };
  const hoverStyle = {
    light: hoverEffect ? 'hover: overflow-visible transition-all duration-300 hover:drop-shadow-xl hover:-translate-y-4 hover:bg-gradient-to-t' : '',
    dark: hoverEffect ? 'hover: overflow-visible transition-all duration-300 hover:drop-shadow-xl hover:-translate-y-4 hover:bg-gradient-to-t hover:border-solid hover:border hover:border-white/75' : ''
  };
  return <div ref={ref} className={clsx(className, link?.href !== '#' && Boolean(link?.href) && 'cursor-pointer', {
    'h-full': heightStretch
  })}>
			<ConditionalWrapper condition={link?.href !== '#' && Boolean(link?.href)} wrapper={children => <Link href={link?.href ?? ''} target={link?.target}>
						{children}
					</Link>}>
				<div className={clsx('self-stretch group p-8 min-h-[230px]', borderRadius && 'rounded-[20px]', hoverStyle[variant], {
        'h-full': heightStretch
      })} style={{
        background: `linear-gradient(${gradientDirection}, ${gradientColorStop1}, ${gradientColorStop2})`,
        backgroundPosition: 'top'
      }}>
					<div className={clsx('flex flex-col items-start justify-between h-full', textColor[variant])}>
						<div className="flex flex-col gap-4">
							{imageSrc && <div className="h-14">
									<Image src={imageSrc.url} alt={imageAlt ?? ''} height={imageWidth / (imageSrc.dimensions.width / imageSrc.dimensions.height)} width={imageWidth} />
								</div>}
							{title && <h5 className="text-h5 text-current">{title}</h5>}
							{text && <p className="text-sm text-current">{text}</p>}
						</div>
						{link?.href !== '#' && Boolean(link?.href) && <Button showIcon={true} variant="subtle" className={clsx(buttonSettings[variant], 'mt-4')}>
								{buttonText}
							</Button>}
					</div>
				</div>
			</ConditionalWrapper>
		</div>;
});